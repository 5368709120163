import { FC, useEffect, useMemo, useState } from "react"
import cx from "classnames"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { FiArrowLeft as ArrowLeft } from "react-icons/fi"
import { usePageTitle } from "@/hooks/usePageTitle"
import { useIsEdit } from "@/hooks/useIsEdit"
import DiscardModal from "@/components/DiscardModal"
import Button from "@/components/Button"
import TextField from "@/components/TextField"
import Textarea from "@/components/Textarea"
import OutlineButton from "@/components/OutlineButton"
import Progress from "@/components/Progress"
import Switch from "@/components/Switch"
import CriteriaStatusSelect from "./features/CriteriaStatusSelect"
import CriteriaMasterListSelect from "./features/CriteriaMasterListSelect"
import CriteriaSummaryPane from "./features/CriteriaSummaryPane"
import { CRITERIA_FILTER_MAP } from "./constants"
import {
  useGetCriteriaFiltersQuery,
  useGetSearchCriteriaQuery,
} from "@/redux/services/searchCriteriaApi"
import { useAppSelector } from "@/redux/hooks"
import {
  getCriteriaSetupInitialState,
  selectCriteriaDescription,
  selectCriteriaFilters,
  selectCriteriaInitialState,
  selectCriteriaMasterListId,
  selectCriteriaName,
  selectCriteriaSetupState,
  selectCriteriaStatus,
  selectCriteriaTarget,
  selectCriteriaType,
} from "@/redux/searchCriteriaSetupSlice"
import { useCriteriaSetup } from "./hooks/useCriteriaSetup"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import { useGetSearchCriteriaMLQuery } from "@/redux/services/masterListsApi"
import { useAddCriteria } from "./hooks/useAddCriteria"
import { useEditSearchCriteria } from "./hooks/useEditSearchCriteria"
import { useUserAccess } from "@/hooks/useUserAccess"
import { useExportSearchCriteria } from "../SearchCriteria/hooks/useExportSearchCriteria"
import { getPercentage } from "@/utils/numbers"
import { ActiveFilterContext } from "./context"
import ReactRouterPrompt from "react-router-prompt"
import ErrorMessage from "@/components/ErrorMessage"
import Loader from "@/components/Loader"
import stringify from "json-stable-stringify"
import DownloadIcon from "@/components/icons/DownloadIcon"
import type {
  FilterType,
  SearchCriteriaStatus,
} from "@/types/entities/search-criteria"

type Props = {
  className?: string
}

const AddEditSearchCriteriaPage: FC<Props> = ({
  className,
  ...rest
}: Props) => {
  const { id: orgId = "", criteriaId = "" } = useParams()
  const [searchParams] = useSearchParams()
  const { pageFullAccess: targetingUpdateFullAccess } = useUserAccess({
    pageName: "Targeting Update",
  })
  const isDuplicate = searchParams.get("mode") === "duplicate"
  const { isFetching: filtersLoading, data: filters = [] } =
    useGetCriteriaFiltersQuery(undefined, {
      refetchOnMountOrArgChange: !isDuplicate,
    })
  const { isLoading: systemValuesLoading } = useGetSystemValuesQuery()
  const { isLoading: MLLoading } = useGetSearchCriteriaMLQuery({
    org: orgId,
    searchCriteria: criteriaId,
  })
  const {
    isLoading: criteriaLoading,
    isError,
    data: criteria,
  } = useGetSearchCriteriaQuery(
    { id: criteriaId },
    { skip: !criteriaId || !filters.length, refetchOnMountOrArgChange: true },
  )
  const name = useAppSelector(selectCriteriaName)
  const description = useAppSelector(selectCriteriaDescription)
  const masterListId = useAppSelector(selectCriteriaMasterListId)
  const status = useAppSelector(selectCriteriaStatus)
  const buildType = useAppSelector(selectCriteriaType)
  const target = useAppSelector(selectCriteriaTarget)
  const filtersMap = useAppSelector(selectCriteriaFilters)
  const setupState = useAppSelector(selectCriteriaSetupState)
  const initialState = useAppSelector(selectCriteriaInitialState)
  const { isEdit, navigatePath } = useIsEdit(criteriaId)
  const navigate = useNavigate()
  const { resetCriteriaSetup, confirmDisabled } = useCriteriaSetup()
  const {
    addCriteria,
    response: { isLoading: addLoading, isSuccess: addSuccess },
  } = useAddCriteria()
  const {
    editCriteria,
    response: { isLoading: editLoading, isSuccess: editSuccess },
  } = useEditSearchCriteria()
  const {
    onNameChange,
    onDescriptionChange,
    onMasterListChange,
    onStatusChange,
    onTypeChange,
    onTargetChange,
    onStrictChange,
    changeInitialState,
  } = useCriteriaSetup()
  usePageTitle(() => (
    <div className="flex gap-3 items-center">
      <div
        onClick={() =>
          navigate({ pathname: isEdit ? "../.." : ".." }, { relative: "path" })
        }
        className="cursor-pointer rounded-full bg-bg-default flex justify-center items-center w-7 h-7"
      >
        <ArrowLeft className="opacity-80 w-[22px] h-[22px]" />
      </div>
      <span>{isEdit ? "Edit" : "Add"} Search Criteria</span>
    </div>
  ))
  useEffect(() => {
    if (!criteriaId) {
      changeInitialState(getCriteriaSetupInitialState())
    }
  }, [criteriaId, changeInitialState])
  useEffect(() => {
    return () => {
      resetCriteriaSetup()
    }
  }, [resetCriteriaSetup])
  const handleChangeActiveFilter = ({
    target,
    currentTarget,
  }: React.MouseEvent<HTMLDivElement>) => {
    if (!(target instanceof HTMLElement) || target.nodeName !== "SPAN") {
      return
    }
    const spanItems = currentTarget.querySelectorAll("span")
    spanItems.forEach((spanEl) => spanEl.classList.remove("active"))
    target.classList.add("active")
    setSelectedFilterName(target.innerText)
  }
  const handleChangeType = (type: string) => () => {
    onTypeChange(type)
  }
  const handleMasterListSelect = (masterListId: string) => {
    onMasterListChange(masterListId)
  }
  const handleStatusChange = (status: SearchCriteriaStatus) => {
    onStatusChange(status)
  }
  const saveCriteria = async () => {
    try {
      const response = isEdit ? await editCriteria({}) : await addCriteria()
      if (response) {
        navigate(navigatePath, { relative: "path" })
      }
    } catch (e) {
      console.error(e)
    }
  }
  const getFilterClassName = (filterName: string) =>
    cx("py-2 px-4", {
      active: selectedFilterName === filterName,
    })

  const handleSubmit = () => saveCriteria()
  const { exportSC, isLoading: exportLoading } = useExportSearchCriteria()

  const [selectedFilterName, setSelectedFilterName] = useState("Keywords")
  const activeFilter = useMemo(
    () =>
      CRITERIA_FILTER_MAP[
        selectedFilterName as keyof typeof CRITERIA_FILTER_MAP
      ],
    [selectedFilterName],
  )
  const handleStrictChange = (checked: boolean) => {
    onStrictChange({
      checked,
      filterType: activeFilter.key as FilterType,
    })
  }

  const filterBody = useMemo(
    () => activeFilter.component,
    [activeFilter.component],
  )
  const filterStrictSupport = useMemo(() => {
    const filterKey = activeFilter.key
    const strictSupported = filters.find(
      ({ type }) => type === filterKey,
    )?.strictSupport
    return strictSupported
  }, [activeFilter.key, filters])
  const strictValue = useMemo(() => {
    if (activeFilter.key === "KEYWORDS" || !filterStrictSupport) {
      return false
    }
    const { strict } = filtersMap[activeFilter.key]
    return strict
  }, [activeFilter.key, filterStrictSupport, filtersMap])
  const percentage = useMemo(
    () => (criteria ? getPercentage(criteria.retrieved, criteria.total, 2) : 0),
    [criteria],
  )
  const isLoading =
    filtersLoading || systemValuesLoading || criteriaLoading || MLLoading
  const dirty = stringify(setupState) !== stringify(initialState)
  const discardModalOpen = dirty && !addSuccess && !editSuccess

  if (isError) {
    return <ErrorMessage />
  }

  return (
    <>
      <div
        className={cx(
          "flex flex-col gap-4 pb-4",
          {
            "justify-center items-center h-full": isLoading,
          },
          className,
        )}
        {...rest}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="flex-1 rounded-md bg-white block-shadow flex flex-col">
              <div
                className={cx("p-6", {
                  faded: !targetingUpdateFullAccess,
                })}
              >
                <div className="flex flex-col lg:flex-row gap-5">
                  <div className="flex-1 flex flex-col gap-5">
                    <TextField
                      value={name}
                      onChange={onNameChange}
                      topLeftLabel="Name"
                      className="input-sm rounded-md"
                      placeholder="Enter search criteria name"
                    />
                    <Textarea
                      value={description}
                      onChange={onDescriptionChange}
                      topLeftLabel="Description"
                      placeholder="Enter search description target"
                      className="resize-none h-32 rounded-md"
                    />
                  </div>
                  <div className="border-[0.5px] border-l-outline"></div>
                  <div className="flex-1 flex flex-col gap-5 [&_.label-text]:pt-2 [&_.label-text]:pb-0.5">
                    <CriteriaMasterListSelect
                      value={masterListId || null}
                      onMasterListSelect={handleMasterListSelect}
                      currentSearchCriteria={criteria}
                    />
                    <CriteriaStatusSelect
                      value={status!}
                      onCriteriaStatusChange={handleStatusChange}
                      label="Status"
                      containerClassName="[&_.label-text]:!py-2"
                    />
                    <div className="flex flex-col lg:flex-row gap-5">
                      <div className="flex flex-col gap-2">
                        <span className="text-xs leading-[18px] font-medium">
                          Build Type
                        </span>
                        <div className="flex gap-2.5">
                          <OutlineButton
                            onClick={handleChangeType("auto")}
                            active={buildType === "auto"}
                            className="btn-sm text-black font-medium min-w-[74px] sm:min-w-[102px]"
                          >
                            Auto
                          </OutlineButton>
                          <OutlineButton
                            onClick={handleChangeType("manual")}
                            active={buildType === "manual"}
                            className="btn-sm text-black font-medium sm:min-w-[102px]"
                          >
                            Manual
                          </OutlineButton>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 flex-1">
                        <span className="text-xs leading-[18px] font-medium">
                          Amount
                        </span>
                        <TextField
                          value={target ?? ""}
                          onChange={onTargetChange}
                          className="input-sm rounded-md"
                          disabled={buildType === "auto"}
                          type="number"
                          placeholder="0"
                          min={50}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-6 pt-3 pb-4 border-y border-y-outline flex flex-col gap-1">
                <div className="flex justify-between text-xs leading-[18px] opacity-60">
                  <span>{percentage}%</span>
                  <span>
                    {criteria
                      ? `${criteria.retrieved}/${criteria.total}`
                      : "0/0"}
                  </span>
                </div>
                <Progress value={percentage} />
              </div>
              <div className="flex flex-col flex-1 lg:flex-row">
                <div
                  onClick={handleChangeActiveFilter}
                  className="basis-1/5 min-w-[276px] lg:border-r border-r-outline px-6 py-2 [&_span]:ease-out [&_span]:transition-[font-weight] [&_span.active]:font-semibold [&_span.active]:text-primary [&_span.active]:rounded-md [&_span.active]:bg-primary-glass [&_span]:cursor-pointer"
                >
                  <h4 className="text-base font-semibold pt-3 pb-1">
                    Top Filters
                  </h4>
                  <div className="flex flex-col text-sm leading-6">
                    <span className={getFilterClassName("Keywords")}>
                      Keywords
                    </span>
                    <span className={getFilterClassName("Spotlights")}>
                      Spotlights
                    </span>
                    <span className={getFilterClassName("Geography")}>
                      Geography
                    </span>
                    <span className={getFilterClassName("Industry")}>
                      Industry
                    </span>
                    <span className={getFilterClassName("School")}>
                      School
                    </span>
                  </div>
                  <h4 className="text-base font-semibold pt-3 pb-1">
                    Role & Tenure Filters
                  </h4>
                  <div className="flex flex-col text-sm leading-6">
                    <span className={getFilterClassName("Seniority Level")}>
                      Seniority Level
                    </span>
                    <span
                      className={getFilterClassName("Year in Current Position")}
                    >
                      Year in Current Position
                    </span>
                    <span
                      className={getFilterClassName("Year at Current Company")}
                    >
                      Year at Current Company
                    </span>
                    <span className={getFilterClassName("Title")}>Title</span>
                  </div>
                  <h4 className="text-base font-semibold pt-3 pb-1">
                    Company Filters
                  </h4>
                  <div className="flex flex-col text-sm leading-6 pb-2">
                    <span className={getFilterClassName("Company")}>
                      Company
                    </span>
                    <span className={getFilterClassName("Company Headcount")}>
                      Company Headcount
                    </span>
                  </div>
                </div>
                <div
                  className={cx(
                    "basis-4/5 px-6 py-5 flex flex-col gap-4 text-sm leading-6",
                    { faded: !targetingUpdateFullAccess },
                  )}
                >
                  <h3 className="text-base leading-6 font-semibold">
                    {selectedFilterName}
                  </h3>
                  {filterStrictSupport && (
                    <div className="flex items-center">
                      <Switch
                        checked={strictValue}
                        onChange={handleStrictChange}
                        id="Strict"
                      />
                      <label className="cursor-pointer pl-2" htmlFor="Strict">
                        Strict
                      </label>
                    </div>
                  )}
                  <ActiveFilterContext.Provider value={activeFilter.key}>
                    {filterBody}
                  </ActiveFilterContext.Provider>
                </div>
              </div>
            </div>
            <CriteriaSummaryPane
              className={cx({ faded: !targetingUpdateFullAccess })}
              canDelete={targetingUpdateFullAccess}
            />
            {targetingUpdateFullAccess && (
              <div className="flex justify-between flex-wrap gap-1 items-center">
                {isEdit ? (
                  <OutlineButton
                    onClick={exportSC}
                    className="btn-base text-black"
                    loading={exportLoading}
                  >
                    <DownloadIcon className="opacity-80 w-5 h-5" />
                    <span className="opacity-80 hidden sm:inline">
                      All Data
                    </span>
                  </OutlineButton>
                ) : (
                  <div></div>
                )}
                <Button
                  disabled={confirmDisabled || addLoading || editLoading}
                  onClick={handleSubmit}
                  className="min-w-[95px] btn-sm self-end"
                >
                  {isEdit ? "Save" : "Confirm"}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      <ReactRouterPrompt when={discardModalOpen}>
        {({ isActive, onConfirm, onCancel }) => (
          <DiscardModal
            open={isActive}
            onConfirm={onConfirm}
            onClose={onCancel}
            canBeActivated
          />
        )}
      </ReactRouterPrompt>
    </>
  )
}

export default AddEditSearchCriteriaPage
